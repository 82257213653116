import React from "react";
import { ButtonStyled, NavDesktopWrapper, UlStyled } from "../Navbar.styled";
import Dropdown from "./Dropdown";

const items = [
  { name: 'About Us', path: '/about' },
  {
    title: 'Student Services', items: [
      { name: 'Needs Assessments', path: '/student-services-assessments' },
      { name: 'Equipment and Software', path: '/student-services-equipment-software' },
      { name: 'AT Training', path: '/student-services-at-training' },
      { name: 'Ergonomic Assessments', path: '/student-services-ergonomic-assessment' },
    ]
  },
  { name: 'DSA Information', path: '/dsa-information' },
  { name: 'Careers', path: '/careers' },
  { name: 'Contact Us', path: '/contact' },
];

const NavDesktop = () => {
  return (
    <NavDesktopWrapper>
      <UlStyled>
        {items.map((item, index) => (
          <li key={index}>
            {item.title ? (
              <Dropdown title={item.title} items={item.items} />
            ) : (
              <ButtonStyled to={item.path} label={item.name}>
                {item.name}
              </ButtonStyled>
            )}
          </li>
        ))}
      </UlStyled>
    </NavDesktopWrapper>
  );
};

export default NavDesktop;