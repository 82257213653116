import { Box } from "@mui/material";
import React from "react";
import AppBar from "../App-bar/AppBar";
import ContainerStyled from "../../../components/Containers/Container";
import { MainWrapper } from "../../../components/Containers/MainWrapper.styled";
import { ContentWrapper, ImgWrapper, Text, Wrapper } from "./ErgonomicAssessment.styled";
import { AppBarText } from "../App-bar/AppBar.styled";
import { IMAGES } from "../../../../app/constants/const";

const ErgonomicAssessment = () => {
  return (
    <Box>
      <AppBar image={IMAGES.student_services.ergonomic_assessment.desktop} mobileImage={IMAGES.student_services.ergonomic_assessment.mobile} label={' Side view of someone working at an ergonomic setup, showing a person in a grey sweater sitting in a white mesh chair with adjustable armrests. Their hands are positioned on a keyboard at a white desk. Plants and natural lighting are visible in the background window area.'} />
      <ContainerStyled>
        <MainWrapper>
          <Wrapper>
            <AppBarText variant='h1'>Ergonomic Assessments</AppBarText>
            <Box>
              <ContentWrapper>
                <Text variant="p"> Following your DSA needs assessment, you may be recommended for an Ergonomic Assessment. This comprehensive evaluation of your workstation and working habits will be conducted face-to-face.</Text>
              </ContentWrapper>
              <ContentWrapper>
                <Text variant="p">If you are eligible, your needs assessor will refer you for an Ergonomic Assessment, and our team will contact you to arrange this. You won't need to schedule this yourself.</Text>
              </ContentWrapper>
              <ContentWrapper>
                <Text variant="p">If you haven't heard from us and believe you are eligible for an Ergonomic Assessment, please contact us by email ergo@study.tech or call us on 0204 532 2084 - Option 5.</Text>
              </ContentWrapper>

              <ImgWrapper>
                <img src={IMAGES.student_services.ergonomic_assessment.content} alt=' Illustration showing inclusive education with three students at computer workstations: one seated, one standing, and one using a wheelchair, representing diverse ways of learning in a university setting.' />
              </ImgWrapper>
            </Box>
          </Wrapper>
        </MainWrapper>
      </ContainerStyled>
    </Box>
  );
};

export default ErgonomicAssessment;
