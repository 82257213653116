import React from 'react';
import Container from '../../components/General/Container';
import { ImgWrapper, MrWrapper, Text, Wrapper } from './CookiePolicy.styled';
import { GeneralText } from '../../components/General/Text';
import { useCurrentScreenWidth } from '../../../hooks/screenSize/useCurrentSceenWidth';
import { IMAGES } from '../../../app/constants/const';

const CookiePolicy = () => {
  const [screenWidth] = useCurrentScreenWidth();
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>Cookie Policy</GeneralText>
        <GeneralText variant='p'>
          This Cookie Policy explains how Study Tech Limited ('we', 'us' or 'our') uses cookies and similar technologies when you visit our website at https://www.study.tech ('website').
        </GeneralText>

        <GeneralText variant='h2'>Overview</GeneralText>
        <GeneralText variant='p'>
          We use cookies and similar technologies to improve your browsing experience, analyse site traffic, and understand where our visitors come from. This policy explains what these technologies are and why we use them, as well as your rights to control their use.
        </GeneralText>
        <GeneralText variant='h2'>What are cookies?</GeneralText>

        <MrWrapper>
          <GeneralText variant='p'>
            Cookies are small text files that are placed on your computer or mobile device when you visit a website. They are widely used to make websites work more efficiently and provide useful information to website owners.
          </GeneralText>
        </MrWrapper>

        <GeneralText variant='h2'>We use two types of cookies:</GeneralText>
        <MrWrapper>
          <GeneralText variant='pBold'>First-party cookies:</GeneralText><GeneralText variant='p'> Set by Study Tech Limited</GeneralText>
          <GeneralText variant='pBold'>Third-party cookies:</GeneralText><GeneralText variant='p'> Set by other companies we work with</GeneralText>
        </MrWrapper>

        <GeneralText variant='h2'>Why we use cookies</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>We use cookies for several purposes:</GeneralText><br />
          <GeneralText variant='p'>- Essential cookies: Required for the website to function properly</GeneralText><br />
          <GeneralText variant='p'>- Analytics cookies: Help us understand how visitors use our website</GeneralText><br />
          <GeneralText variant='p'>- Functionality cookies: Remember your preferences and choices</GeneralText><br />
          <GeneralText variant='p'>- Performance cookies: Help us improve our website's performance</GeneralText><br />
        </MrWrapper>

        <Text variant='semiBold'>Cookie categories on our website</Text>
        <GeneralText variant='h2'>Essential cookies</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>These cookies are strictly necessary for our website to work properly. You cannot opt out of these cookies.</GeneralText>
        </MrWrapper>
        <GeneralText variant='h2'>Analytics and customisation cookies</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>We use these to understand how our website is being used and to improve your experience.</GeneralText><br /><br />
          <ImgWrapper>
            <img src={screenWidth <= 750 ? IMAGES.cookie.mobile1 : IMAGES.cookie.desktop1} alt='We use these to understand how our website is being used and to improve your experience.' />
          </ImgWrapper>
        </MrWrapper>

        <GeneralText variant='h2'>Unclassified cookies</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>We are currently working to categorise these cookies:</GeneralText><br /><br />
          <ImgWrapper>
            <img src={screenWidth <= 750 ? IMAGES.cookie.mobile2 : IMAGES.cookie.desktop2} alt='We are currently working to categorise these cookies:' />
          </ImgWrapper>
        </MrWrapper>

        <Text variant='semiBold'>Your choices regarding cookies</Text>
        <GeneralText variant='h2'>Cookie Consent Manager</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>You can manage your cookie preferences through our Cookie Consent Manager, available in the notification banner and on our website. Essential cookies cannot be rejected as they are strictly necessary for the website to function.</GeneralText>
        </MrWrapper>
        <GeneralText variant='h2'>Browser Settings</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>You can also control cookies through your browser settings. Here's how to manage cookies in common browsers:</GeneralText><br />
          <GeneralText variant='p'>- Chrome</GeneralText><br />
          <GeneralText variant='p'>- Firefox</GeneralText><br />
          <GeneralText variant='p'>- Safari</GeneralText><br />
          <GeneralText variant='p'>- Microsoft Edge</GeneralText><br />
          <GeneralText variant='p'>- Opera</GeneralText><br />
        </MrWrapper>
        <GeneralText variant='h2'>Other tracking technologies</GeneralText>

        <MrWrapper>
          <GeneralText variant='p'>We may also use:</GeneralText><br />
          <GeneralText variant='p'>- Web beacons ('tracking pixels')</GeneralText><br />
          <GeneralText variant='p'>- Clear GIFs</GeneralText><br />
          <GeneralText variant='p'>- Local Shared Objects ('Flash cookies')</GeneralText><br /><br />
          <GeneralText variant='p'>These help us understand how you use our website and measure the effectiveness of our communications.</GeneralText><br />
        </MrWrapper>

        <GeneralText variant='h2'>Updates to this policy</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            We may update this Cookie Policy periodically. The date of 18th November 2024 of this policy indicates when it was last updated. Please review this policy regularly to stay informed about how we use cookies.
          </GeneralText>
        </MrWrapper>
      </Wrapper>
    </Container>
  );
};

export default CookiePolicy;
