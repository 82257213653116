import React from 'react';
import Container from '../../components/General/Container';
import { Card, CardContent, CardImage, ContentWrapper, DsaStudentContentWrapper, ImgWrapper, Text, Wrapper } from './DsaStudents.styled';
import { GeneralText } from '../../components/General/Text';
import { Box } from '@mui/material';
import { IMAGES } from '../../../app/constants/const';


const DsaStudents = () => {


  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>Disabled Students’ Allowance (DSA) How it works</GeneralText>
        <GeneralText variant='p'>
          The DSA is a government grant that supports students with recognised conditions during their time at a Higher Education Institution (HEI).
        </GeneralText>

        <ImgWrapper>
          <img src={IMAGES.dsa_student.dsaStudents} alt='A cartoon illustration showing three people collaboratively assembling large puzzle pieces. One person in navy blue stands holding a yellow puzzle piece, another in a yellow jumper reaches with a grey piece, and a third person in navy crouches while holding another yellow piece. The scene is set against a pale yellow background with subtle office-related icons, surrounded by a navy blue border. The illustration represents teamwork and creative problem-solving.' />
        </ImgWrapper>

        <GeneralText variant='h2'>DSA Process</GeneralText>

        <ContentWrapper>
          <Card>
            <CardImage>
              <img src={IMAGES.dsa_student.place_order} alt='Apply (document icon)' />
            </CardImage>
            <CardContent >
              <Text variant='title'>
                1. Apply
              </Text>
              <Text variant='pSemiBold'>
                Apply directly to Student Finance for DSA funding.
              </Text>
            </CardContent>
          </Card>

          <Card>
            <CardImage>
              <img src={IMAGES.dsa_student.document} alt='Approval (document with checkmark)' />
            </CardImage>
            <CardContent >
              <Text variant='title'>
                2. Approval
              </Text>
              <Text variant='pSemiBold'>
                You will receive approval from Student Finance for an assessment. Book your assessment by calling, emailing, or registering through our portal via the email invitation we'll send you.
              </Text>
            </CardContent>
          </Card>

          <Card>
            <CardImage>
              <img src={IMAGES.dsa_student.assessment} alt='Assessment (two people meeting)' />
            </CardImage>
            <CardContent >
              <Text variant='title'>
                3. Assessment
              </Text>
              <Text variant='pSemiBold'>
                Following your booking, you'll meet with a Study Needs Assessor to discuss your support requirements. This informal meeting can take place either online or in person. After the meeting, your Assessor will provide a report with recommendations for equipment, training and support.
              </Text>
            </CardContent>
          </Card>

          <Card>
            <CardImage>
              <img src={IMAGES.dsa_student.order} alt='Order (envelope)' />
            </CardImage>
            <CardContent >
              <Text variant='title'>
                4. Order
              </Text>
              <Text variant='pSemiBold'>
                Once Student Finance approves the recommendations, we will contact you to:
              </Text>
              <Text variant='pSemiBold'>
                -  Confirm your equipment and support package
                <br />
                -  Offer options to upgrade your technology
              </Text>
            </CardContent>
          </Card>

          <Card>
            <CardImage>
              <img src={IMAGES.dsa_student.training} alt='Training (graduation cap)' />
            </CardImage>
            <CardContent >
              <Text variant='title'>
                5. Training
              </Text>
              <Text variant='pSemiBold'>
                We will arrange your training sessions alongside your equipment order. Training is essential for mastering the recommended technology and achieving the best possible outcomes in your studies.
              </Text>
            </CardContent>
          </Card>

          <Card>
            <CardImage>
              <img src={IMAGES.dsa_student.support} alt='Support (handshake)' />
            </CardImage>
            <CardContent >
              <Text variant='title'>
                6. Support
              </Text>
              <Text variant='pSemiBold'>
                Throughout your course, you'll have access to our technical support teams and assessors to ensure your recommended technology continues to work effectively.
              </Text>
            </CardContent>
          </Card>
        </ContentWrapper>
        <DsaStudentContentWrapper><GeneralText variant='h2'>What is DSA?</GeneralText></DsaStudentContentWrapper>
        <Box>
          <GeneralText variant='p'>
            The Disabled Students' Allowance provides support to help you excel in higher education by addressing challenges you might face, whether they relate to academic studies, physical accessibility or overall well-being.
          </GeneralText>
          <ContentWrapper>
            <GeneralText variant='p'>
              Although it's called an 'allowance', you won't receive money directly. Instead, when you are awarded DSA, you can arrange support through approved providers (based on your location) who receive funding directly from your student finance body.
            </GeneralText>
          </ContentWrapper>
          <ContentWrapper>
            <GeneralText variant='p'>Eligibility<br /></GeneralText>
            <GeneralText variant='p'>You may be eligible for DSA if you have:<br /></GeneralText>
            <GeneralText variant='p'>- A disability<br /></GeneralText>
            <GeneralText variant='p'>- A mental health condition<br /></GeneralText>
            <GeneralText variant='p'>- A neurodivergent condition<br /></GeneralText>
            <GeneralText variant='p'>- A chronic illness<br /></GeneralText>
            <GeneralText variant='p'>- A learning difference<br /></GeneralText>
          </ContentWrapper>
          <ContentWrapper>
            <Text variant='p'>If you're unsure about your <a href='https://www.gov.uk/disabled-students-allowance-dsa/eligibility' className='email-link' target='_blank' aria-label='eligibility' rel="noreferrer">eligibility</a>, please visit the government's eligibility criteria.</Text>
          </ContentWrapper>
          <ContentWrapper>
            <Text variant='p'>How to Apply<br />
              Visit <a href='https://www.gov.uk/disabled-students-allowance-dsa' target='_blank' aria-label='Student Finance England' className='email-link' rel="noreferrer">Student Finance England</a> or <a href='https://www.studentfinancewales.co.uk/' target='_blank' aria-label='Student Finance Wales' className='email-link' rel="noreferrer">Student Finance Wales</a> to start your application.</Text>
          </ContentWrapper>
        </Box>
      </Wrapper>
    </Container>
  );
};

export default DsaStudents;
