import React from 'react';
import { ContentWrapper, Wrapper } from './ContactUs.styled';
import { GeneralText } from '../../components/General/Text';
import { Box } from '@mui/material';
import AppBar from '../Student-services/App-bar/AppBar';
import ContainerStyled from '../../components/Containers/Container';
import { MainWrapper } from '../../components/Containers/MainWrapper.styled';
import { AppBarText } from '../Student-services/App-bar/AppBar.styled';
import { IMAGES } from '../../../app/constants/const';

const ContactUs = () => {
  return (
    <Box>
      <AppBar image={IMAGES.contact_us.desktop} mobileImage={IMAGES.contact_us.mobile} label={' Close-up shot of hands typing on a laptop keyboard, showing manicured fingers with neutral nail polish. The image is cropped to focus on the keyboard interaction and features a navy blue curved overlay at the bottom. The photo has a shallow depth of field, creating a soft, professional aesthetic.'} />
      <ContainerStyled>
        <MainWrapper>
          <Wrapper>
            <AppBarText variant='h1'>Contact Us</AppBarText>
            <Box>
              <ContentWrapper>
                <GeneralText variant='pBold'>You can reach us during our regular business days of Monday through Friday.</GeneralText>
              </ContentWrapper>
              <ContentWrapper>
                <GeneralText variant='pBold'>Telephone:</GeneralText><GeneralText variant='p'> 0204 532 2084</GeneralText>
              </ContentWrapper>
              <ContentWrapper>
                <GeneralText variant='p'>Option 1 for Needs Assessments <br /></GeneralText>
                <GeneralText variant='p'>Option 2 for Orders <br /></GeneralText>
                <GeneralText variant='p'>Option 3 for AT Training<br /></GeneralText>
                <GeneralText variant='p'>Option 4 for Technical Support <br /></GeneralText>
                <GeneralText variant='p'>Option 5 for Ergonomics <br /></GeneralText>
              </ContentWrapper>

              <ContentWrapper>
                <GeneralText variant='pBold'>Email us at:</GeneralText>
              </ContentWrapper>
              <ContentWrapper>
                <a href='mailto:assessments@study.tech' className='email-link' aria-label='assessments@study.tech'>
                  assessments@study.tech
                </a>
                <br />
                <a href='mailto:orders@study.tech' className='email-link' aria-label='orders@study.tech'>
                  orders@study.tech
                </a>
                <br />
                <a href='mailto:att@study.tech' className='email-link' aria-label='att@study.tech'>
                  att@study.tech
                </a>
                <br />
                <a href='mailto:techsupport@study.tech' className='email-link' aria-label='techsupport@study.tech'>
                  techsupport@study.tech
                </a>
                <br />
                <a href='mailto:ergo@study.tech' className='email-link' aria-label='ergo@study.tech'>
                  ergo@study.tech
                </a>
                <br />
                <br />
                <a href='mailto:media@study.tech' className='email-link' aria-label='media@study.tech'>
                  media@study.tech
                </a>
                <br />
                <a href='mailto:opportunity@study.tech' className='email-link' aria-label='opportunity@study.tech'> 
                  opportunity@study.tech
                </a>
              </ContentWrapper>

            </Box>
          </Wrapper>
        </MainWrapper>
      </ContainerStyled>
    </Box>
  );
};

export default ContactUs;
