export const navItems = [
  { title: 'About us', path: '/about' },
  { title: 'Press Release', path: '/press-release' },
  { title: 'FAQs', path: '/frequently-asked-questions' },
  { title: 'DSA Information', path: '/dsa-information' },
  { title: 'Careers', path: '/careers' },
  { title: 'Contact us', path: '/contact' }
];

export const navItemsTest = [
  // {
  //   title: 'About us',
  //   links: [
  //     { title: 'About us', path: '/about' },
  //     { title: 'Our Mission', path: '/mission' }
  //   ]
  // },
  {
    title: 'Student Services',
    links: [
      { title: 'Assessments', path: '/student-services-assessments' },
      { title: 'Equipment and Software', path: '/student-services-equipment-software' },
      { title: 'AT Training', path: '/student-services-at-training' },
      { title: 'Ergonomic Assessments', path: '/student-services-ergonomic-assessment' }
    ]
  },
  // {
  //   title: 'DSA Information',
  //   links: [
  //     { title: 'DSA Information', path: '/dsa-information' },
  //     { title: 'What are the DSA?', path: '/what-are-the-dsa' },
  //     { title: 'DSA Eligibility', path: '/dsa-eligibility' },
  //     { title: 'Student Loans Company (SLC)', path: '/student-loans-company' }
  //   ]
  // },
  // {
  //   title: 'Support',
  //   links: [{ title: 'HEP / University Information', path: '/hep-university-information' }]
  // },
  {
    title: 'FAQs',
    links: [
      { title: 'Assessments', path: '/frequently-asked-questions-assessments' },
      { title: 'AT Training', path: '/frequently-asked-questions-at-training' },
      { title: 'Equipment', path: '/frequently-asked-questions-equipment' }
    ]
  }
];

export const navItemsWithoutDropDown = [
  // { title: 'BUZZ', path: '/buzz' },

  { title: 'About us', path: '/about' },
  // { title: 'Press Release', path: '/press-release' },
  // { title: 'FAQs', path: '/frequently-asked-questions' },
  { title: 'DSA Information', path: '/dsa-Information' },
  { title: 'Careers', path: '/careers' },
  { title: 'Contact us', path: '/contact' }
];

export const fundingBodyOptions = [
  {
    label: 'SFE - Student Finance England',
    value: 'SFE - Student Finance England'
  },
  {
    label: 'SAAS - Student Awards Agency Scotland',
    value: 'SAAS - Student Awards Agency Scotland'
  },
  {
    label: 'SFW - Student Finance Wales',
    value: 'SFW - Student Finance Wales'
  }
];

export const yearOfStudyOptions = [
  {
    label: '1 Year',
    value: '1 Year'
  },
  {
    label: '2 Year',
    value: '2 Year'
  },
  {
    label: '3 Year',
    value: '3 Year'
  },
  {
    label: '4 Year',
    value: '4 Year'
  },
  {
    label: '5 Year',
    value: '5 Year'
  },
  {
    label: '6 Years or longer',
    value: '6 Years or longer'
  }
];

export const preferredContactOptions = [
  {
    label: 'Email',
    value: 'Email'
  },
  {
    label: 'Phone',
    value: 'Phone'
  }
];

export const EMAILJS_API = 'https://api.emailjs.com/api/v1.0/email/send';


export const API = 'https://study.tech';



export const IMAGES = {
  about: `${API}/images/about/about.png`,
  at_training: {
    desktop: `${API}/images/at-training/atTrainig.svg`,
    mobile: `${API}/images/at-training/atTrainingMobile.svg`
  },
  careers: `${API}/images/careers/careers.png`,
  contact_us: {
    desktop: `${API}/images/contact-us/contactUsBg.svg`,
    mobile: `${API}/images/contact-us/contactUsBgMobile.svg`
  },
  cookie: {
    desktop1: `${API}/images/cookie/table.svg`,
    mobile1: `${API}/images/cookie/tableMob.svg`,
    desktop2: `${API}/images/cookie/table2.svg`,
    mobile2: `${API}/images/cookie/table2mob.svg`,
  },
  dsa_student: {
    assessment: `${API}/images/dsa-students/assessment.svg`,
    document: `${API}/images/dsa-students/document.svg`,
    dsaStudents: `${API}/images/dsa-students/dsaStudents.png`,
    order: `${API}/images/dsa-students/order.svg`,
    support: `${API}/images/dsa-students/support.svg`,
    training: `${API}/images/dsa-students/training.svg`,
    place_order: `${API}/images/student-services/equipment/placeOrder.svg`
  },
  home: {
    desktop: `${API}/images/home/BackgroundWeb.svg`,
    mobile: `${API}/images/home/BackgrounMobile.svg`,
    each_session: `${API}/images/home/EachSession.png`,
    equpment: `${API}/images/home/equpment.svg`,
    ergonomic_assessment: `${API}/images/home/ergonomic-assessment.svg`,
    FocusOnProductivity: `${API}/images/home/FocusOnProductivity 1.png`,
    needsAssessment: `${API}/images/home/needsAssessment.svg`,
    SaveTime: `${API}/images/home/SaveTime.png`,
    training: `${API}/images/home/training.svg`,
    upload: `${API}/images/home/upload.svg`,
  },
  student_services: {
    equipment: {
      delivery: `${API}/images/student-services/equipment/delivery.svg`,
      desktop: `${API}/images/student-services/equipment/equipment-bg.svg`,
      mobile: `${API}/images/student-services/equipment/equipment-bg-mobile.svg`,
      place_order: `${API}/images/student-services/equipment/placeOrder.svg`,
      star: `${API}/images/student-services/equipment/star.svg`,
    },
    ergonomic_assessment: {
      desktop: `${API}/images/student-services/ergonomic-assessment/ergonomicAssessmentBg.svg`,
      mobile: `${API}/images/student-services/ergonomic-assessment/ergonomicAssessmentBgMobile.svg`,
      content: `${API}/images/student-services/ergonomic-assessment/ergonomicAssessmentContent.svg`,
    },
    needs_assessment: {
      content: `${API}/images/student-services/needs-assessment/needAssessment.svg`,
      desktop: `${API}/images/student-services/needs-assessment/needsAssessmentBg.svg`,
      mobile: `${API}/images/student-services/needs-assessment/needsAssessmentBgMobile.svg`,

    }
  }
};