import * as React from 'react';

import {
  AppBarWrapper,
  WrapperMobile,
} from './AppBar.styled';
import { useCurrentScreenWidth } from '../../../../hooks/screenSize/useCurrentSceenWidth';
import { IMAGES } from '../../../../app/constants/const';

export default function AppBar() {
  const [screenWidth] = useCurrentScreenWidth();
  return screenWidth <= 750 ? (
    <WrapperMobile>
      <img src={IMAGES.home.mobile} alt='A person seated outdoors on a chair, looking at a laptop with a scenic mountain landscape in the background. The person is wearing a warm hat and sweater, and the misty mountains are bathed in soft, golden sunlight, creating a peaceful atmosphere.' />
    </WrapperMobile>
  ) : (
    <>
      <AppBarWrapper>
        <img src={IMAGES.home.desktop} alt='A person seated outdoors on a chair, looking at a laptop with a scenic mountain landscape in the background. The person is wearing a warm hat and sweater, and the misty mountains are bathed in soft, golden sunlight, creating a peaceful atmosphere.' />
      </AppBarWrapper>
    </>
  );
}
