import React from 'react';
import { Wrapper } from '../student-services-assessment/StudentServicesAssessment.styled';
import { Box } from '@mui/material';
import { AppBarText } from '../App-bar/AppBar.styled';
import AppBar from '../App-bar/AppBar';
import ContainerStyled from '../../../components/Containers/Container';
import { MainWrapper } from '../../../components/Containers/MainWrapper.styled';
import Equipment from './Equipment';
import { IMAGES } from '../../../../app/constants/const';


const EquipmentSoftware = () => {
  return (
    <Box>
      <AppBar image={IMAGES.student_services.equipment.desktop} mobileImage={IMAGES.student_services.equipment.mobile} label={'A modern desktop workstation setup featuring a widescreen monitor displaying a colourful wallpaper with blue, purple and green waves. The desk includes a wireless keyboard, mouse, and what appears to be a second monitor partially visible on the left. The setup sits on a white desk surface.'} />
      <ContainerStyled>
        <MainWrapper>
          <Wrapper>
            <AppBarText variant='h1'>Equipment and Software</AppBarText>
            <Equipment />
          </Wrapper>
        </MainWrapper>
      </ContainerStyled>
    </Box>
  );
};

export default EquipmentSoftware;
