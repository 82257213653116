import React from "react";
import { CloseIconStyled, DrawerStyled, Link, LogoWrapper, MobileMenuAppBar, MobileMenuContent } from "./MobileMenu.styled";
import Logo from '../../../../assets/icons/navbar/Logo.svg';
import { Box, IconButton } from "@mui/material";
import ControlledAccordion from "./ControlledAccordion";

const data = [
  {
    title: 'Student Services',
    items: [
      { name: 'Needs Assessments', path: '/student-services-assessments', sx: '16px' },
      { name: 'Equipment and Software', path: '/student-services-equipment-software', sx: '16px' },
      { name: 'AT Training', path: '/student-services-at-training', sx: '16px' },
      { name: 'Ergonomic Assessments', path: '/student-services-ergonomic-assessment', sx: '16px' },
    ]
  },
  { name: 'DSA Information', path: '/dsa-information', sx: '16px' },
  {
    title: 'FAQs',
    items: [
      { name: 'Needs Assessments', path: '/frequently-asked-questions-assessments', sx: '16px' },
      { name: 'Equipment and Software', path: '/frequently-asked-questions-equipment', sx: '16px' },
      { name: 'AT Training', path: '/frequently-asked-questions-at-training', sx: '16px' },
      { name: 'Ergonomic Assessments', path: '/frequently-asked-questions-ergonomics', sx: '16px' },
    ]
  },
  {
    title: 'Legal',
    items: [
      { name: 'Privacy Policy', path: '/privacy-policy', sx: '16px' },
      { name: 'Cookie Policy', path: '/cookie-policy', sx: '16px' },
      { name: 'Complaints Policy', path: '/complaints-policy', sx: '16px' },
      { name: 'Accessibility', path: '/accessibility', sx: '16px' },
      { name: 'Modern Slavery Act', path: '/modern-slavery-act', sx: '16px' },
      { name: 'Terms and Conditions', path: '/terms-and-conditions', sx: '16px' },
      { name: 'Refund Policy', path: '/refund-policy', sx: '16px' }
    ]
  },
  {
    title: 'Company',
    items: [
      { name: 'About Us', path: '/about', sx: '16px' },
      { name: 'Careers', path: '/careers' }
    ]
  },
  { name: 'Contact us', path: '/contact', sx: '16px' },
];


const MobileMenu = ({ open, handleDrawerToggle }) => {
  return (
    <DrawerStyled
      open={open}
      variant='temporary'
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true
      }}
    >
      <MobileMenuAppBar >
        <LogoWrapper>
          <img src={Logo} alt='Study Tech home' />
        </LogoWrapper>

        <IconButton type="button" aria-label="Close menu" onClick={handleDrawerToggle}>
          <CloseIconStyled />
        </IconButton>
      </MobileMenuAppBar>

      <MobileMenuContent>
        {data.map((item, index) => (
          item.title ?
            <ControlledAccordion
              item={item}
              key={index}
              handleDrawerToggle={handleDrawerToggle}
            /> :
            <Box key={index} sx={{
              height: '51px',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Link
                to={item.path}
                label={item.name}
                fontSize={'16px'}
                fontFamily={'Open Sans SemiBold'}
                onClick={handleDrawerToggle}
              >{item.name}
              </Link>
            </Box>
        ))}

      </MobileMenuContent>
    </DrawerStyled>
  );
};

export default MobileMenu;
