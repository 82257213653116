import React from 'react';
import Container from '../../components/General/Container';
import { BoxStyled, ContentWrapper, ImgWrapper, Wrapper } from './Careers.styled';
import { GeneralText } from '../../components/General/Text';
import { IMAGES } from '../../../app/constants/const';

const Careers = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>Careers</GeneralText>
        <GeneralText variant='bold'>
          Join Our Mission<br /> Help us create a more inclusive world through assistive technology. At Study Tech, we attract talented individuals who share our commitment to this vision.
        </GeneralText>
        <BoxStyled>
          <ImgWrapper>
            <img src={IMAGES.careers} alt={'An illustration in a minimalist style showing a hand holding an CV against a pale yellow background with a navy blue border. The document contains a profile picture icon, some abstract text lines, and a yellow check mark or verification symbol. The design suggests document approval.'} />
          </ImgWrapper>
        </BoxStyled>
        <GeneralText variant='h2'>Current Opportunities</GeneralText>
        <GeneralText variant='pBold'>
          DSA Needs Assessors and Assistive Technology Trainers
        </GeneralText>
        <GeneralText variant='p'>
          <ul>
            <li><GeneralText variant='p'>Freelance</GeneralText></li>
            <li><GeneralText variant='p'>Part-Time</GeneralText></li>
            <li><GeneralText variant='p'>Full-Time</GeneralText></li>
          </ul>
        </GeneralText>
        <ContentWrapper>
          <GeneralText variant='pBold'>
            DSA Needs Assessors and Assistive Technology Trainers<br />
          </GeneralText>
          <GeneralText variant='p'>
            We are a leading provider of assistive technology, seeking skilled and dedicated professionals to join our friendly team. With recent changes to the Disabled Students' Allowance (DSA), we are entering an exciting new era of assistive technology support.
          </GeneralText>
        </ContentWrapper>
        <ContentWrapper>
          <GeneralText variant='pBold'>We're Looking For People Who Have:</GeneralText><br />
          <GeneralText variant='p'>
            <ul>
              <li>Experience as a DSA Needs Assessor or Assistive Technology Trainer</li>
              <li>A personable and approachable working style</li>
              <li>The ability to adapt to diverse disabilities and learning needs</li>
            </ul>
          </GeneralText>
        </ContentWrapper>

        <ContentWrapper>
          <GeneralText variant='pBold'>Employment Options</GeneralText><br />
          <GeneralText variant='p'>
            <ul>
              <li>Freelance contracts</li>
              <li>Part-time positions</li>
              <li>Full-time roles</li>
            </ul>
          </GeneralText>
        </ContentWrapper>

        <ContentWrapper>
          <GeneralText variant='pBold'>How to Apply</GeneralText><br />
          <GeneralText variant='p'>
            If you're interested in joining our team, please:
            <ul>
              <li>Send your CV</li>
              <li>Include a brief expression of interest</li>
              <li>Email your application to <a href='mailto:opportunity@study.tech' className='email-link'>
                opportunity@study.tech
              </a></li>
            </ul>
          </GeneralText>
        </ContentWrapper>
        <ContentWrapper>
          <GeneralText variant='p'>
            We are actively recruiting and look forward to working together to empower learners through technology.
          </GeneralText>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default Careers;