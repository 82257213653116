import React from 'react';
import { ContentWrapper, ImgWrapper, Text, Wrapper } from './StudentServicesAssessment.styled';
import { Box } from '@mui/material';
import AppBar from '../App-bar/AppBar';
import ContainerStyled from '../../../components/Containers/Container';
import { MainWrapper } from '../../../components/Containers/MainWrapper.styled';
import { AppBarText } from '../App-bar/AppBar.styled';
import { IMAGES } from '../../../../app/constants/const';


const StudentServicesAssessment = () => {
  return (
    <Box>
      <AppBar image={IMAGES.student_services.needs_assessment.desktop} mobileImage={IMAGES.student_services.needs_assessment.mobile} label={'A modern desktop workstation setup featuring a widescreen monitor displaying a colourful wallpaper with blue, purple and green waves. The desk includes a wireless keyboard, mouse, and what appears to be a second monitor partially visible on the left. The setup sits on a white desk surface.'} />
      <ContainerStyled>
        <MainWrapper>
          <Wrapper>
            <AppBarText variant='h1'>Needs Assessments</AppBarText>
            <Box>
              <ContentWrapper>
                <Text variant='p'>Great news for students who are entitled to DSA. Study Tech has a team of experienced assessors ready to provide tailored assessments.</Text>
              </ContentWrapper>
              <ContentWrapper>
                <Text variant='p'>A DSA needs assessment is a meeting with an experienced, friendly assessor who will discuss your individual condition and how it affects your studies. The assessor will listen and then make tailored recommendations to fund the most suitable support. The Student Loans Company will then review and approve these recommendations.</Text>
              </ContentWrapper>

              <ImgWrapper>
                <img src={IMAGES.student_services.needs_assessment.content} alt='An illustration of an evaluation scene. Three colleagues are shown: one person in a yellow jumper seated at a laptop, another in red engaged in conversation across the desk, and a woman in a white t-shirt and dark trousers standing by a large monitor display. The monitor shows a assessment form with tick boxes and rating stars. The setting includes decorative indoor plants and a navy blue background with a cream-coloured accent area.' />
              </ImgWrapper>
            </Box>
          </Wrapper>
        </MainWrapper>
      </ContainerStyled>
    </Box>
  );
};

export default StudentServicesAssessment;
