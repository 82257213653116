import { useLocation } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Navbar from './components/NavBar/Navbar';
import MainRouter from './routes/MainRouter';
import './styles/App.css';
import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';

const pageTitles = {
  '/': 'StudyTech',
  '/about': 'About Us',
  '/student-services-assessments': 'Needs Assessments',
  '/student-services-equipment-software': 'Equipment & Software',
  '/student-services-at-training': 'AT Training',
  '/student-services-ergonomic-assessment': 'Ergonomic Assessment',
  '/frequently-asked-questions-at-training': 'AT Training FAQs',
  '/frequently-asked-questions-assessments': 'Assessment FAQs',
  '/frequently-asked-questions-equipment': 'Equipment FAQs',
  '/frequently-asked-questions-ergonomics': 'Ergonomic FAQs',
  '/pre-assessment-form': 'Pre-Assessment Form',
  '/careers': 'Careers',
  '/contact': 'Contact Us',
  '/complaints-policy': 'Complaints Policy',
  '/privacy-policy': 'Privacy Policy',
  '/cookie-policy': 'Cookie Policy',
  '/dsa-information': 'DSA Information',
  '/accessibility': 'Accessibility',
  '/modern-slavery-act': 'Modern Slavery Act',
  '/terms-and-conditions': 'Terms & Conditions',
  '/refund-policy': 'Refund Policy',
  '/sitemap': 'Sitemap',
};

function App() {
  const { pathname } = useLocation();
  
  const nonce =
    Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);



  return (
    <>
      <Helmet>
        <title>{pageTitles[pathname]}</title>
        <script async src='https://www.googletagmanager.com/gtag/js?id=G-W9GRQN9Z68'></script>
        <script nonce={nonce}>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-W9GRQN9Z68');
          `}
        </script>
        <meta charSet='utf-8' />
      <meta
          httpEquiv='Content-Security-Policy'
          content={`default-src 'self' 'unsafe-inline' 'unsafe-eval' https:; 
                    script-src 'self' https://www.googletagmanager.com/ 'nonce-${nonce}'; 
                    style-src  'self' 'unsafe-inline' 'unsafe-eval' https: data:;
                    img-src 'self' data:; 
                    connect-src 'self' https://www.google-analytics.com;`}></meta>
      </Helmet>
      <a href="#main-content" className="skip-link">Skip to main content</a>
      <header>
        <Navbar />
      </header>
      <main role="main" id='main-content' aria-label="Main content">
        <MainRouter />
      </main>
      <Footer />
    </>
  );
}

export default App;
