import React from 'react';
import Container from '../../../components/General/Container';
import { ContentWrapper, ImgWrapper, Wrapper } from './About.styled';
import { GeneralText } from '../../../components/General/Text';
import { IMAGES } from '../../../../app/constants/const';


const About = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>About us</GeneralText>
        <GeneralText variant='pBold'>Our Mission</GeneralText><br />
        <GeneralText variant='p'>
          To enhance the academic journey and well-being of customers receiving Disabled Students' Allowances (DSA). Through bespoke tools and personalised support, we help reduce academic stress whilst fostering success in both education and life. With the right tools and guidance, every customer can excel academically and confidently transition to their next chapter.
        </GeneralText>
        <ImgWrapper>
          <img src={IMAGES.about} alt='Three people high-fiving, with a desk chair and computer screen in the background.' />
        </ImgWrapper>
        <ContentWrapper>
          <GeneralText variant='pBold'>Our Expertise</GeneralText><br />
          <GeneralText variant='p'>
            As a dedicated team of assistive technology specialists, we bring years of experience supporting tens of thousands of customers throughout their educational journeys. Working in partnership with Student Finance, we are committed to modernising DSA services and delivering enhanced outcomes for our customers.
          </GeneralText>
        </ContentWrapper>
        <ContentWrapper>
          <GeneralText variant='pBold'>Our Philosophy</GeneralText><br />
          <GeneralText variant='p'>
            We recognise that everyone learns differently. Whilst traditional education often follows a standardised approach, we embrace personalisation. Our expert assessors and trainers act as facilitators, conducting collaborative assessments and crafting bespoke product packages. Through comprehensive one-to-one support, we develop personalised study strategies that address each customer's specific requirements.
          </GeneralText>
        </ContentWrapper>
        <ContentWrapper>
          <GeneralText variant='pBold'>Our Approach</GeneralText><br />
          <GeneralText variant='p'>
            We take a holistic approach to customer support, beginning with thorough assessments that identify the precise tools needed for each customer's success. Our commitment extends beyond simply providing equipment. We carefully build and configure each system to match individual requirements, ensuring everything works seamlessly together. When we spot additional needs, we go the extra mile to provide enhanced support.
          </GeneralText>
        </ContentWrapper>

        <ContentWrapper>
          <GeneralText variant='pBold'>Our Responsive Team</GeneralText><br />
          <GeneralText variant='p'>
            Prioritises swift resolution of technical issues, and we never leave a customer without support. Whether it's following up on enquiries, providing training, or troubleshooting problems, we maintain consistent communication throughout the customer's journey. Through close collaboration with universities, the Student Loans Company, Welsh Government, the SAAS and the Department for Education, we ensure comprehensive support that adapts to changing customer needs.
          </GeneralText>
        </ContentWrapper>

        <ContentWrapper>
          <GeneralText variant='p'>
            At Study Tech, we are dedicated to education that is inclusive, accessible and enabling for all.
          </GeneralText>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default About;
