import React from 'react';
import { Content, ImageWrapper, SecondBlockWrapper, SecondContentText } from './SecondBlock.styled';
import { GeneralText } from '../../../../../components/General/Text';
import { Text } from '../../StudentServicesAtTraining.styled';
import { IMAGES } from '../../../../../../app/constants/const';
const SecondBlock = () => {
  return (
    <SecondBlockWrapper>
      <ImageWrapper>
        <img src={IMAGES.home.FocusOnProductivity} alt='Illustration of a person in a yellow shirt thinking, with a lightbulb and connected idea icons floating above their head on a dark blue background.' />
      </ImageWrapper>
      <Content>
        <Text variant='subtitleSemibold'>Focus on productivity</Text>
        <SecondContentText>
          <GeneralText variant='p'>
            Increasing productivity isn't about working harder— it's about working smarter.
          </GeneralText>
        </SecondContentText>
        <GeneralText variant='pSemibold'>
          "I explored new working methods with my AT Trainer. While some strategies weren't suitable for me, others were invaluable. We delved deeper into the approaches that worked best, and I've completely transformed my essay-writing process, saving days compared to my previous methods."
        </GeneralText>
      </Content>
    </SecondBlockWrapper>
  );
};

export default SecondBlock;
