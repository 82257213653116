import { Box } from "@mui/material";
import AppBar from "../App-bar/AppBar";
import ContainerStyled from "../../../components/Containers/Container";
import { MainWrapper } from "../../../components/Containers/MainWrapper.styled";
import { Wrapper } from './StudentServicesAtTraining.styled';
import { AppBarText } from "../App-bar/AppBar.styled";
import Blocks from "./Blocks";
import { IMAGES } from "../../../../app/constants/const";

const StudentServicesAtTraining = () => {
  return (
    <Box>
      <AppBar image={IMAGES.at_training.desktop} mobileImage={IMAGES.at_training.mobile} label={'A modern desktop workstation setup featuring a widescreen monitor displaying a colourful wallpaper with blue, purple and green waves. The desk includes a wireless keyboard, mouse, and what appears to be a second monitor partially visible on the left. The setup sits on a white desk surface.'} />
      <ContainerStyled>
        <MainWrapper>
          <Wrapper>
            <AppBarText variant='h1'>AT Training</AppBarText>
            <Blocks />
          </Wrapper>
        </MainWrapper>
      </ContainerStyled>
    </Box>
  );
};

export default StudentServicesAtTraining;
