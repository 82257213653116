import React from 'react';
import Container from '../../components/General/Container';
import { MrWrapper, Wrapper } from './Accessibility.styled';
import { GeneralText } from '../../components/General/Text';
import { Link } from 'react-router-dom';

const Accessibility = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>Accessibility</GeneralText>
        <GeneralText variant='p'>
          Study Tech is an inclusive organisation, and we strive to provide an experience for
          everyone who visits our website that is both welcoming and accessible.
        </GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            To achieve this, we proactively apply an approach that considers different needs and
            accessibility, enabling technology in line with the best practices and standards defined
            by the web content accessibility guidelines - WCAG 2.2 (AA).
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            By making our websites fully accessible to everyone, we aim to ensure our visitors
            experience Study Tech’s commitment to diversity and inclusion.
          </GeneralText>
        </MrWrapper>

        <GeneralText variant='h2'>We want you to find what you need.</GeneralText>
        <GeneralText variant='p'>
          We have made our internal and external sites compatible with as many browsers, platforms,
          and hardware as possible, including desktop computers, laptops, tablets, and smartphones.
          Should you have any questions or suggestions or wish to bring any issues or
          inconsistencies to our attention, please{' '}
          <Link to='/contact' className='email-link'>
            contact us
          </Link>
          .
        </GeneralText>

        <GeneralText variant='h2'>We provide accessibility tools and tips.</GeneralText>
        <GeneralText variant='p'>
          Accessing our websites has never been easier; however, below are a few tips to personalise
          your experience.
        </GeneralText>

        <GeneralText variant='h2'>Making pages easier to read</GeneralText>

        <GeneralText variant='p'>
          When text is too small web pages can be difficult to view. Several popular browsers
          including Internet Explorer, Chrome, Firefox, Safari, and Opera allow you to zoom in and
          out using your mouse and keyboard shortcuts, as follows (use the Control key in Windows
          systems, and the Command key in mac OS):
        </GeneralText>
        <ul>
          <li>
            <GeneralText variant='p'>
              Hold the Control/Command key and scroll up or down to zoom in or out
            </GeneralText>
          </li>
          <li>
            <GeneralText variant='p'>
              Hold the Control/Command key and press + or – to zoom in or out
            </GeneralText>
          </li>
        </ul>

        <GeneralText variant='h2'>Magnifying your screen</GeneralText>
        <GeneralText variant='p'>It may be more convenient to magnify your screen.</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            All recent versions of Windows include a magnifier, which can enlarge a portion of the
            screen. However, you may find you require fully functional magnification software.
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            You can access more information about screen magnifiers in{' '}
            <a
              href='https://support.microsoft.com/en-us/windows/use-magnifier-to-make-things-on-the-screen-easier-to-see-414948ba-8b1c-d3bd-8615-0e5e32204198#ID0EBBF=Windows_10'
              target='_blank'
              rel='noreferrer'
              className='email-link' aria-label='Windows 10'>
              Windows 10
            </a>
            ,{' '}
            <a
              className='email-link'
              href='https://support.microsoft.com/en-us/windows/use-magnifier-to-make-things-on-the-screen-easier-to-see-414948ba-8b1c-d3bd-8615-0e5e32204198#ID0EBBF=Windows_8.1'
              target='_blank'
              rel='noreferrer' aria-label='Windows 8.1'>
              Windows 8.1
            </a>{' '}
            and{' '}
            <a
              className='email-link'
              href='https://support.microsoft.com/en-us/windows/use-magnifier-to-make-things-on-the-screen-easier-to-see-414948ba-8b1c-d3bd-8615-0e5e32204198#ID0EBBF=Windows_7'
              target='_blank'
              rel='noreferrer' aria-label='Windows 7'>
              Windows 7
            </a>{' '}
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            Users running{' '}
            <a
              className='email-link'
              href='https://www.apple.com/uk/accessibility/#vision'
              target='_blank'
              rel='noreferrer' aria-label='Apple’s OS X operating systems'>
              Apple’s OS X operating systems
            </a>{' '}
            can use the in-built Zoom feature.
          </GeneralText>
        </MrWrapper>

        <GeneralText variant='h2'>Using a screen reader</GeneralText>

        <MrWrapper>
          <GeneralText variant='p'>
            Screen reader programs will read the content of the web page to the user.
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            Windows users can access Windows Narrator which converts on-screen text into speech.
            This is available in{' '}
            <a
              href='https://support.microsoft.com/en-us/windows/use-magnifier-to-make-things-on-the-screen-easier-to-see-414948ba-8b1c-d3bd-8615-0e5e32204198#ID0EBBF=Windows_10'
              target='_blank'
              rel='noreferrer'
              className='email-link' aria-label='Windows 10'>
              Windows 10
            </a>
            ,{' '}
            <a
              className='email-link'
              href='https://support.microsoft.com/en-us/windows/use-magnifier-to-make-things-on-the-screen-easier-to-see-414948ba-8b1c-d3bd-8615-0e5e32204198#ID0EBBF=Windows_8.1'
              target='_blank'
              rel='noreferrer' aria-label=' Windows 8.1'>
              Windows 8.1
            </a>{' '}
            and{' '}
            <a
              className='email-link'
              href='https://support.microsoft.com/en-us/windows/use-magnifier-to-make-things-on-the-screen-easier-to-see-414948ba-8b1c-d3bd-8615-0e5e32204198#ID0EBBF=Windows_7'
              target='_blank'
              rel='noreferrer' aria-label='Windows 7'>
              Windows 7
            </a>
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            In Apple’s OSX operating system, users will find the{' '}
            <a
              className='email-link'
              href='https://www.apple.com/uk/accessibility/#vision'
              target='_blank'
              rel='noreferrer' aria-label='VoiceOver'>
              VoiceOver
            </a>{' '}
            feature.
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            Third-party screen readers are also available such as{' '}
            <a
              className='email-link'
              href='https://www.freedomscientific.com/Products/software/JAWS/'
              target='_blank'
              rel='noreferrer' aria-label='JAWS'>
              JAWS
            </a>{' '}
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            (Job Access With Speech) and the freeware program{' '}
            <a
              className='email-link'
              href='https://www.nvaccess.org/'
              target='_blank'
              rel='noreferrer' aria-label='NVDA'>
              NVDA
            </a>{' '}
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            Android users can access the{' '}
            <a
              className='email-link'
              href='https://support.google.com/accessibility/android/answer/6283677?hl=en#:~:text=TalkBack%2520is%2520the%2520Google%2520screen,without%2520looking%2520at%2520the%2520screen.'
              target='_blank'
              rel='noreferrer' aria-label='Talkback'>
              Talkback
            </a>{' '}
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            feature & iPhone or iPad users can access the{' '}
            <a
              className='email-link'
              href='https://www.apple.com/sg/accessibility/mobility/'
              target='_blank'
              rel='noreferrer'
              aria-label='Voiceover'
            >
              Voiceover
            </a>{' '}
            feature PDF files
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            We use PDF files to help us preserve the look and feel of our original documents. To
            open and view PDF files, you can download the latest version of{' '}
            <a
              className='email-link'
              href='https://www.adobe.com/acrobat/pdf-reader.html'
              target='_blank'
              rel='noreferrer'
              aria-label='Adobe Acrobat Reader'
            >
              Adobe Acrobat Reader
            </a>{' '}
            free of charge.
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            Adobe also publishes information on{' '}
            <a
              className='email-link'
              href='https://wwwimages2.adobe.com/content/dam/cc/en/accessibility/pdfs/accessing-pdf-sr.pdf'
              target='_blank'
              rel='noreferrer'
              aria-label='accessing-pdf-sr.pdf'
            >
              accessing PDFs using screen readers (PDF)
            </a>{' '}
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            Accessibility statement for Study Tech Website - please follow this{' '}
            <a
              className='email-link'
              href='/Accessibility_statement_for_Study_Tech_Website_Feb24.pdf'
              target='_blank'
              aria-label='Accessibility_statement_for_Study_Tech_Website_Feb24.pdf'
              rel='noreferrer'>
              Download the accessibility statement
            </a>
            .
          </GeneralText>
        </MrWrapper>
      </Wrapper>
    </Container>
  );
};

export default Accessibility;
